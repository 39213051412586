import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IoClose, IoMenu } from "react-icons/io5";
import './Navbar.css';

function Navbar() {
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
            setShowMenu(false);
        }
    }

    return (
        <header className="nav_header fixed w-full bg-gray-800 shadow-lg z-50">
            <nav className="nav">
                <NavLink to="/">
                    <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" className='nav__logo' />
                </NavLink>

                <div
                    className={`nav__menu ${showMenu ? "show-menu" : ""}`}
                    id="nav-menu"
                >
                    <ul className="nav__list">
                        <li className="nav__item">
                            <NavLink
                                to="/"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                Start
                            </NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink
                                to="/test"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                Test
                            </NavLink>
                        </li>
                        {/* <li className="nav__item">
                            <NavLink
                                to="/contact"
                                className="nav__link"
                                onClick={closeMenuOnMobile}
                            >
                                Kontakt
                            </NavLink>
                        </li> */}
                    </ul>
                    <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                        <IoClose />
                    </div>
                </div>

                <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                    <IoMenu />
                </div>
            </nav>
        </header>



        // <div className='Navbar'>
        //     <div>
        //         <Link to="/">Home</Link>
        //     </div>
        //     <div>
        //         <Link to="/test">Test</Link>
        //     </div>
        //     <div>
        //         <Link to="/about">About</Link>
        //     </div>
        // </div>
    )

}

export default Navbar