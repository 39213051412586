import React from 'react';
import './Home.css';

// Komponenty nawigacji
// const Navigation = () => (
//   <header className="fixed w-full bg-gray-800 shadow-lg z-50">
//     <nav className="container mx-auto px-6 py-4">
//       <div className="flex items-center justify-between">
//         <div className="text-2xl font-bold text-blue-400">CodeCraft</div>
//         <div className="hidden md:flex space-x-8">
//           <a href="#oferta" className="text-gray-300 hover:text-blue-400">Oferta</a>
//           <a href="#technologie" className="text-gray-300 hover:text-blue-400">Technologie</a>
//           <a href="#proces" className="text-gray-300 hover:text-blue-400">Proces</a>
//           <a href="#kontakt" className="text-gray-300 hover:text-blue-400">Kontakt</a>
//         </div>
//       </div>
//     </nav>
//   </header>
// );

// Komponent Hero
const Hero = () => (
  <section className="pt-24 bg-gradient-to-br from-gray-800 to-blue-900 text-white min-h-screen flex items-center">
    <div className="container mx-auto px-6 py-20 text-center">
      <h1 className="text-5xl md:text-6xl font-bold mb-8">
        Twoje rozwiązania IT szyte na miarę
      </h1>
      <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-gray-300">
        Zamieniamy Twoje pomysły w działające oprogramowanie. Specjalizujemy się w tworzeniu
        dedykowanych rozwiązań, które odpowiadają unikalnym potrzebom Twojej firmy.
      </p>
      {/* <a href="#kontakt" className="bg-blue-500 text-white px-8 py-4 rounded-lg font-semibold hover:bg-blue-600 inline-block transition-colors">
        Rozpocznij projekt
      </a> */}
    </div>
  </section>
);

// Komponent karty usługi
const ServiceCard = ({ title, features }: { title: any, features: any }) => (
  <div className="p-6 border border-gray-700 rounded-lg bg-gray-900 shadow-lg hover:shadow-xl transition-shadow">
    <h3 className="text-2xl font-bold mb-4 text-blue-400">{title}</h3>
    <ul className="space-y-3 text-gray-300">
      {features.map((feature: any, index: any) => (
        <li key={index}>✓ {feature}</li>
      ))}
    </ul>
  </div>
);

// Sekcja usług
const Services = () => {
  const services = [
    {
      title: "Aplikacje Webowe",
      features: [
        "Responsywne i przyjazne dla użytkownika",
        "Zoptymalizowane pod kątem wydajności",
        "Bezpieczne i niezawodne",
        "Łatwe w utrzymaniu i rozbudowie"
      ]
    },
    {
      title: "Aplikacje Mobilne",
      features: [
        "Spójna jakość na iOS i Android",
        "Natywna wydajność",
        "Intuicyjny interfejs",
        "Optymalne wykorzystanie zasobów"
      ]
    },
    {
      title: "Systemy Dedykowane",
      features: [
        "Automatyzacja procesów",
        "Integracja systemów",
        "Analityka i raportowanie",
        "Skalowalność i bezpieczeństwo"
      ]
    }
  ];

  return (
    <section id="oferta" className="py-20 bg-gray-800">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-16 text-blue-400">Nasze usługi</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

// Komponent technologii
const TechnologyCard = ({ title, description }: { title: any, description: any }) => (
  <div className="p-6 text-center">
    <h3 className="text-xl font-bold text-blue-400 mb-4">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

// Sekcja technologii
const Technologies = () => {
  const technologies = [
    {
      title: "C# & .NET Core",
      description: "Solidny fundament dla wydajnych i skalowalnych aplikacji serwerowych"
    },
    {
      title: "React & JavaScript",
      description: "Nowoczesne i dynamiczne interfejsy użytkownika"
    },
    {
      title: "Xamarin & MAUI",
      description: "Cross-platformowe aplikacje mobilne nowej generacji"
    }
  ];

  return (
    <section id="technologie" className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-16 text-blue-400">Nasze technologie</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          {technologies.map((tech, index) => (
            <TechnologyCard key={index} {...tech} />
          ))}
        </div>
      </div>
    </section>
  );
};

// Komponent kroku procesu
const ProcessStep = ({ number, title, description }: { number: any, title: any, description: any }) => (
  <div className="text-center">
    <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center text-white text-2xl font-bold mx-auto mb-4">
      {number}
    </div>
    <h3 className="text-xl font-bold mb-2 text-blue-400">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

// Sekcja procesu
const Process = () => {
  const steps = [
    { title: "Analiza", description: "Poznanie potrzeb i celów projektu" },
    { title: "Projektowanie", description: "Architektura i interfejs" },
    { title: "Rozwój", description: "Iteracyjne wytwarzanie" },
    { title: "Wdrożenie", description: "Uruchomienie i szkolenia" },
    { title: "Wsparcie", description: "Ciągła opieka techniczna" }
  ];

  return (
    <section id="proces" className="py-20 bg-gray-800">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-16 text-blue-400">Proces współpracy</h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {steps.map((step, index) => (
            <ProcessStep key={index} number={index + 1} {...step} />
          ))}
        </div>
      </div>
    </section>
  );
};

// // Formularz kontaktowy
// const ContactForm = () => (
//   <section id="kontakt" className="py-20 bg-gray-900">
//     <div className="container mx-auto px-6">
//       <h2 className="text-4xl font-bold text-center mb-16 text-blue-400">Skontaktuj się z nami</h2>
//       <div className="max-w-2xl mx-auto">
//         <form className="space-y-6">
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//             <div>
//               <label className="block text-gray-300 mb-2">Imię i nazwisko</label>
//               <input type="text" className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500" />
//             </div>
//             <div>
//               <label className="block text-gray-300 mb-2">Email</label>
//               <input type="email" className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500" />
//             </div>
//           </div>
//           <div>
//             <label className="block text-gray-300 mb-2">Temat</label>
//             <input type="text" className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500" />
//           </div>
//           <div>
//             <label className="block text-gray-300 mb-2">Wiadomość</label>
//             <textarea className="w-full p-3 rounded-lg bg-gray-800 border border-gray-700 text-white h-32 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"></textarea>
//           </div>
//           <button className="w-full bg-blue-500 text-white py-4 rounded-lg font-semibold hover:bg-blue-600 transition-colors">
//             Wyślij wiadomość
//           </button>
//         </form>
//       </div>
//     </div>
//   </section>
// );

// Stopka
const Footer = () => (
  <footer className="bg-gray-800 text-white py-12">
    <div className="container mx-auto px-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <h3 className="text-2xl font-bold mb-4 text-blue-400">Wind Fx</h3>
          <p className="text-gray-400">Tworzymy rozwiązania, które napędzają Twój biznes</p>
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4 text-blue-400">Kontakt</h4>
          <p className="text-gray-400"><a href="mailto: piotr.wiatr@windfx.pl" className="text-gray-400">Email:  piotr.wiatr@windfx.pl </a></p>
          {/* <p className="text-gray-400">Tel: +48 123 456 789</p> */}
        </div>
        <div>
          <h4 className="text-xl font-bold mb-4 text-blue-400">Adres</h4>
          <p className="text-gray-400">ul. Kolejowa 24</p>
          <p className="text-gray-400">27-620 Dwikozy</p>
        </div>
      </div>
      <div className="border-t border-gray-700 mt-12 pt-8 text-center text-gray-400">
        <p>&copy; 2024 Wind Fx. Wszelkie prawa zastrzeżone.</p>
      </div>
    </div>
  </footer>
);

// Główny komponent aplikacji
const Home = () => (
  <div className="bg-gray-900 text-gray-100">
    {/* <Navigation /> */}
    <Hero />
    <Services />
    <Technologies />
    <Process />
    {/* <ContactForm /> */}
    <Footer />
  </div>
);

export default Home;