import React from 'react';
import './Test.css';

function Test() {
  return (
    <div>
      <header className='page_title'>
        TEST
      </header>
      <div className='page_description'>
        Tu znajdują się informacje na temat instancji testowych tworzonych lub rozwijanych systemów.
      </div>
      <body>
        <div className='test_body'>

          <table className='test_instances_table'>
            <tr>
              <td className='test_instance_title'>OnkoRad</td>
              <td>
                <div className='test_instance_components'>
                  <table className='test_instance_components_table'>
                    <tr>
                      <td ><a href="https://radonk.windfx.pl/" style={{ color: 'var(--first-color)' }}> Aplikacja administracyjna </a></td>
                    </tr>
                    <tr>
                      <td ><a href="https://play.google.com/store/apps/details?id=com.windfx.rad_onk_mobile" style={{ color: 'var(--first-color)' }}> Klient (Android) </a></td>
                    </tr>
                    <tr>
                      <td ><a href="https://apps.apple.com/pl/app/onkorad/id6736402787?l=pl" style={{ color: 'var(--first-color)' }}> Klient (iOS) </a></td>
                    </tr>
                    <tr>
                      <td ><a href="https://radonk.windfx.pl/Privacy_Policy_OnkoRad.htm" style={{ color: 'var(--first-color)' }}> Polityka prywatności </a></td>
                    </tr>
                  </table>
                </div>
              </td>
              <td className='test_instance_description'>
                System do zarządzania pacjentami onkologicznymi.<br /><br />
                System umożliwia:<br />
                - edukację pacjenta w zakresie prowadzonego zakresu zabiegów,<br />
                - kontrolowanie terminów spotkań poprzez kalendarz, zarządzany z poziomu aplikacji
                administracyjnej,<br />
                - przesyłanie ankiet od pacjenta + eksport ankiet do pliku csv,<br />
                - dwustronny czat z zapisem historii wiadomości pomiędzy lekarzem i pacjentem,<br />
                - wskazówki dojazdu i poruszania się po szpitalu,<br />
                - integrację z zewnętrznymi systemami do zarządzania radioterapią,<br />
                - wysyłanie powiadomień push/email do użytkowników.<br /><br />
                Użyte technologie, biblioteki i usługi:<br />
                .Net Core, Xamarin iOS + Android, Entity
                Framework, MySQL, React Admin, Google Firebase, Hangfire.
              </td>
            </tr>
          </table>
        </div>
      </body>
    </div>
  );
}

export default Test